var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invoice-templates"},[_c('VueTable',{attrs:{"title":"Invoice Templates","has-search":false,"headers":_vm.headers,"loading":_vm.loading,"items":_vm.items,"no-more-data":_vm.noMoreData,"show-select":false},on:{"load-more":_vm.getMoreInvoiceTemplates},scopedSlots:_vm._u([{key:"header-toolbar",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 action-btn",attrs:{"fab":""},on:{"click":_vm.open_add_dialog}},'v-icon',attrs,false),on),[_vm._v("add_circle_outline")])]}}])},[_c('span',[_vm._v("Create Template")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 action-btn",attrs:{"fab":""},on:{"click":_vm.open_help_dialog}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Invoice Template Guide and Rules")])])]},proxy:true},{key:"row-slot",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("ucwords")(item.name)))]),(item.creator)?_c('td',[_c('Avatar',{attrs:{"user":item.creator}})],1):_c('td',[_c('v-avatar',[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-monitor-clean")])],1),_vm._v(" System Default ")],1),(item.company_id === 0)?_c('td'):_c('td',[_vm._v(_vm._s(_vm._f("bzFromNow")(item.created_at)))]),_c('Actions',{attrs:{"has-delete":_vm.can_action(item) && _vm.can_delete(item),"has-edit":_vm.can_action(item),"item":item,"permissions":_vm.$_permissions.get('templates')},on:{"delete":function($event){return _vm.open_delete_dialog(item)},"edit":function($event){return _vm.open_edit_dialog(item)},"view":function($event){return _vm.open_view_dialog(item)}}})]}},{key:"empty-slot",fn:function(){return [_c('v-btn',{attrs:{"dark":"","color":"primary"},on:{"click":_vm.open_add_dialog}},[_vm._v("Add new invoice template")])]},proxy:true}])}),_c('InvoiceTemplateDialog',{ref:"add_edit_dialog",attrs:{"default-templates":_vm.defaultTemplates,"invoicefields":_vm.invoicefields},on:{"save":_vm.handleSave,"preview":_vm.open_view_dialog}}),_c('InvoiceTemplatePreview',{ref:"view_dialog",attrs:{"item":_vm.activeItem,"invoicefields":_vm.invoicefields}}),_c('InvoiceTemplateHelp',{ref:"help_dialog",attrs:{"invoicefields":_vm.invoicefields}}),_c('delete-dialog',{attrs:{"open-dialog":_vm.delete_dialog,"title":"Delete Template","text-content":"Are you sure you want to delete this invoice template?"},on:{"update:openDialog":function($event){_vm.delete_dialog=$event},"update:open-dialog":function($event){_vm.delete_dialog=$event},"delete":function($event){return _vm.delete_item('delete_invoice_template', $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }