<template>
  <div class="invoice-template-dialog">
    <CustomDialog
      :has-footer="false"
      :open.sync="open"
      :title="dialogTitle"
      @button1="cancel"
      :max-width="900"
    >
      <template v-slot:content>
        <v-row wrap class="custom-dialog">
          <v-col grow v-if="item">
            <iframe
              class="iframe"
              :style="style"
              :srcdoc="parseTemplate"
              @onload="resizeIframe"
            />
          </v-col>
        </v-row>
      </template>
    </CustomDialog>
  </div>
</template>
<script>
import CustomDialog from "@/common/BaseComponents/CustomDialog/CustomDialog.vue";

export default {
  components: {
    CustomDialog,
  },

  props: {
    item: { type: Object },
    value: Boolean,
    invoicefields: { type: [Array, Object], required: true },
  },

  data: () => ({
    open: false,
    invoice: null,
  }),

  computed: {
    style() {
      return `min-height:470px; width: 100%;border:none;`;
    },
    dialogTitle() {
      return this.item ? this.item.name : "View Template";
    },
    parseTemplate() {
      if (!this.item.template) return "";
      let template = this.item.template;
      if (typeof template === "string") {
        const keys = Object.keys(this.invoicefields);
        for (const key of keys) {
          let myRegExp = new RegExp("{" + key + "}", "g");
          template = template.replace(
            myRegExp,
            this.invoicefields[key].mock_data
          );
        }
      }

      return template;
    },
  },

  watch: {
    value(val) {
      this.open = val;
      if (val) this.btnloading = false;
    },
    open(val) {
      this.$emit("input", val);
    },
    item: {
      handler: function (val) {},
      deep: true,
      immediate: true,
    },
  },

  methods: {
    cancel() {
      this.open = false;
    },
    resizeIframe(obj) {
      obj.style.height =
        obj.contentWindow.document.documentElement.scrollHeight + "px";
    },
  },
};
</script>